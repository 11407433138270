$primaryColor: #20B36C;		//zelena
$secondaryColor: #20B36C;	//zelena
$tertiaryColor: #ffffff;		//biela
$quaternaryColor: red;	//
$pentiaryColor: green;		//
$sixthColor: #5a5046;    //siva
$whiteColor:  #FFFFFF;

$errorColor: #cc0000;
$warningColor: #ffcc00;
$successColor: #33cc00;

$body-font-family: 'Ubuntu', sans-serif !important;
$base-font-size: 1rem;
$base-line-height: 1.5rem;

$collapsing_width: 1320px;
$gutter: 15px;
