@CHARSET 'UTF-8' {}

@import '../variables';
@import '../mixins';

.example-prev-next {
	display: none; }

.static_main_home {
	margin-top: 30px;
	margin-bottom: 30px;
	text-align: center;
	h1 {
		text-transform: uppercase;
		font-size: 2em;
		letter-spacing: 1px;
		font-weight: bold; }
	hr {
		border: 3px solid $primaryColor;
		width: 200px; } }

#slider,.cycle-slideshow li {
	height: 300px !important; }
footer {
	position: relative !important; }
